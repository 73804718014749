import React from "react";

import { OpenAi,Claude,GVertaxAI,AWS,Meta,Azure} from '../../assets'


function PoweredBy(){
    return (
        <div className="container text-center py-5">
            <h2 className="fw-bold mb-4 mt-3">
                Powered By <span className="text-primary">Cutting-Edge</span> Technologies
            </h2>
            <p className="mb-4">
                We work with all the major technologies, incorporating the latest and most renowned tech stacks and platforms.
            </p>
            <div className="row justify-content-center mb-3">
                <div className="col-md-4 col-sm-6 mb-3">
                    <div className="card shadow p-3" style={{backgroundColor:"#F4F2FA"}} >
                        <img src={OpenAi} alt="OpenAI" className="card-img-top mx-auto d-block" style={{ height: "50px", width: "50px" }} />
                        <div className="card-body">
                            <h5 className="card-title">OpenAI</h5>
                            <p className="card-text">o1, GPT4, ChatGPT, DALL-E, Whisper</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 mb-3">
                    <div className="card shadow p-3" style={{backgroundColor:"#F4F2FA"}}>
                        <img src={Claude} alt="Claude" className="card-img-top mx-auto d-block" style={{ height: "50px", width: "50px" }} />
                        <div className="card-body">
                            <h5 className="card-title">Claude</h5>
                            <p className="card-text">Claude 3 Opus, Claude 3.5 Sonnet</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 mb-3">
                    <div className="card shadow p-3" style={{backgroundColor:"#F4F2FA"}}>
                        <img src={AWS} alt="AWS Bedrock" className="card-img-top mx-auto d-block" style={{ height: "50px", width: "50px" }} />
                        <div className="card-body">
                            <h5 className="card-title">AWS Bedrock</h5>
                            <p className="card-text">Cohere, Mistral AI, Stability AI</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 mb-3">
                    <div className="card shadow p-3" style={{backgroundColor:"#F4F2FA"}}>
                        <img src={GVertaxAI} alt="Google Vertex.AI" className="card-img-top mx-auto d-block" style={{ height: "50px", width: "50px" }} />
                        <div className="card-body">
                            <h5 className="card-title">Google Vertex.AI</h5>
                            <p className="card-text">Gemini, BERT, Med PaLM</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 mb-3">
                    <div className="card shadow p-3" style={{backgroundColor:"#F4F2FA"}}>
                        <img src={Meta} alt="Meta" className="card-img-top mx-auto d-block" style={{ height: "50px", width: "50px" }} />
                        <div className="card-body">
                            <h5 className="card-title">Meta</h5>
                            <p className="card-text">MetaAI, LLaMA 3.1, SAM 2</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 mb-3">
                    <div className="card shadow p-3" style={{backgroundColor:"#F4F2FA"}}>
                        <img src={Azure} alt="Azure" className="card-img-top mx-auto d-block" style={{ height: "50px", width: "50px" }} />
                        <div className="card-body">
                            <h5 className="card-title">Azure</h5>
                            <p className="card-text">OpenAI, AI Search, AI Speech Analytics</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PoweredBy