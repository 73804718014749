import "./App.scss";
import AppRouter from "./routes/Router";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        {/* <title>Vikgol | Crafting Superior Software with a Focus on Code Quality Excellence</title> */}
        <title>
          Vikgol | Crafting Superior Software with a Focus on Code Quality Excellence
        </title>
        <link rel="canonical" href="https://www.vikgol.com"></link>
        <meta
          name="description"
          content="Vikgol: Expert Software Development for FinTech, Healthcare & EdTech
          |Leveraging AI Technologies to Transform Your Business Ideas | Custom
          Solutions & Staff Augmentation"
        />
      </Helmet>
      <AppRouter />
    </>
  );
}

export default App;
